import React from "react";
import { Grid, Box } from "@material-ui/core";

import SayingCard from "./sayingCard";

import { MysqlSaying } from "gatsby-types";

type Props = {
  sayings: MysqlSaying[];
  col: number;
  randomThumbnail?: boolean;
};

export default function SayingList({
  sayings,
  col,
  randomThumbnail = true,
}: Props) {
  const countPerCol = sayings.length / col < 1 ? 1 : sayings.length / col;
  return (
    <Grid container spacing={3}>
      {col == 3 ? (
        <>
          {[
            sayings.slice(0, 0 + countPerCol),
            sayings.slice(
              0 + countPerCol + 1,
              0 + countPerCol + 1 + countPerCol
            ),
            sayings.slice(
              0 + countPerCol + 1 + countPerCol + 1,
              0 + countPerCol + 1 + countPerCol + 1 + countPerCol
            ),
          ].map((slicedSayings) => (
            <Grid item xs={12} sm={6} lg={4}>
              {slicedSayings.map((slicedSaying) => (
                <>
                  <SayingCard
                    key={slicedSaying.mysqlId}
                    saying={slicedSaying}
                    randomThumbnail={randomThumbnail}
                  />
                  <Box height="12px" />
                </>
              ))}
            </Grid>
          ))}
        </>
      ) : col == 2 ? (
        <>
          {[
            sayings.slice(0, 0 + countPerCol),
            sayings.slice(
              0 + countPerCol + 1,
              0 + countPerCol + 1 + countPerCol
            ),
          ].map((slicedSayings) => (
            <Grid item xs={12} sm={6} lg={4}>
              {slicedSayings.map((slicedSaying) => (
                <>
                  <SayingCard
                    key={slicedSaying.mysqlId}
                    saying={slicedSaying}
                    randomThumbnail={randomThumbnail}
                  />
                  <Box height="12px" />
                </>
              ))}
            </Grid>
          ))}
        </>
      ) : (
        <>
          {[sayings.slice(0, 0 + countPerCol)].map((slicedSayings) => (
            <Grid item xs={12} sm={6} lg={4}>
              {slicedSayings.map((slicedSaying) => (
                <>
                  <SayingCard
                    key={slicedSaying.mysqlId}
                    saying={slicedSaying}
                    randomThumbnail={randomThumbnail}
                  />
                  <Box height="12px" />
                </>
              ))}
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
}
