import * as React from "react";
import { Typography, useTheme, useMediaQuery, Box } from "@material-ui/core";
import type { graphql, HeadFC, PageProps } from "gatsby";
import Layout from "../layouts/layout";
import SayingList from "../components/sayingList";
import { Link } from "../components/link";

const IndexPage = ({ data }) => {
  const { tags, topics, study } = data;
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const col = isLg ? 3 : isSm ? 2 : 1;
  return (
    <>
      <Layout kv={true}>
        <>
          <Typography
            component="h2"
            style={{ margin: "12px 0", fontWeight: "bold" }}
          >
            トピックから名言を探す
          </Typography>
          {topics.nodes.map((topic) => (
            <Typography
              component="span"
              style={{ marginRight: "12px", marginBottom: "4px" }}
            >
              <Link
                style={{ marginRight: "18px" }}
                to={`/topics/${topic.mysqlId}/page/1`}
              >
                {topic.name}
              </Link>
            </Typography>
          ))}
          <Typography
            component="h2"
            style={{ margin: "12px 0", fontWeight: "bold" }}
          >
            職業や肩書きから名言を探す
          </Typography>
          {tags.nodes.map((tag) => (
            <Typography
              component="span"
              style={{ marginRight: "12px", marginBottom: "4px" }}
            >
              <Link
                style={{ marginRight: "18px" }}
                to={`/tags/${tag.mysqlId}/page/1`}
              >
                {tag.name}
              </Link>
            </Typography>
          ))}

          <Box
            style={{
              margin: "28px auto",
              marginBottom: "42px",
              position: "relative",
              maxWidth: "720px",
            }}
          >
            <Typography
              component="h2"
              style={{
                fontWeight: "bold",
                textAlign: "center",
                backgroundColor: "white",
                width: "220px",
                margin: "0 auto",
                position: "relative",
                top: "12px",
              }}
            >
              人気の名言
            </Typography>
            <Box height={0} style={{ borderTop: "1px solid #ccc" }} />
          </Box>
          <SayingList sayings={study.nodes} col={col} />
        </>
      </Layout>
    </>
  );
};
export const query = graphql`
  query {
    tags: allMysqlTag {
      nodes {
        mysqlId
        name
      }
    }
    topics: allMysqlTopic {
      nodes {
        mysqlId
        name
      }
    }
    study: allMysqlSaying(
      filter: {
        mysqlId: {
          in: [
            25255
            280
            21237
            12488
            17197
            20655
            17591
            25784
            5566
            24824
            11835
            19674
            7468
            25096
            22907
            3026
            14814
            15920
            12703
          ]
        }
      }
    ) {
      nodes {
        mysqlId
        content
        person {
          mysqlId
          name
        }
      }
    }
  }
`;

export default IndexPage;

export const Head: HeadFC = () => (
  <title>【まるっと名言集】偉人やアニメなどの名言を抜粋</title>
);
